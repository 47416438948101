import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.paragraph.lg};
  font-family: ${p => p.theme.fonts.sansSerif};
  color: ${p => p.theme.colors.black};
  letter-spacing: .1rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: left;

  &.noBreak {
    br{
      display: none !important;
    }
  }

  &.alt {
    p {
      font-size: 1.6rem;
      margin-bottom: 2rem;
      line-height: 1.5;
      letter-spacing: -0.032rem;
    }
    i, em{
      font-style: italic;
    }
    h1,h2,h3,h4,h5,h6 {
      font-family: ${p => p.theme.fonts.sansSerif};
      font-weight: bold;
      color: ${p => p.theme.colors.black};
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: -0.032rem;
      margin-bottom: 4rem;
    }
  }

  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.paragraph.xs};
  }

  b, strong {
    font-weight: bold;
  }

  p > b, p > strong {
    color: ${({ theme }) => theme.colors.greyText};
  }

  p {
    font-size: 1.6rem;
    font-family: ${p => p.theme.fonts.sansSerif};
    margin-bottom: 2rem;
    line-height: 1.5;
    letter-spacing: -0.032rem;
    text-align: left;

    ${mediaMax.xs} {
      font-size: ${({ theme }) => theme.fontSizes.paragraph.xs};
    }
  }

  ul {
    margin-top: 2rem;
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    color: ${p => p.theme.colors.grey};
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    list-style: inside;

    li {
      line-height: 1.8rem;
    }
  }

  table {
    color: ${p => p.theme.colors.grey};
    tr {

      td {
        padding: 1rem 0;
        border-bottom: 1px solid ${p => p.theme.colors.grey}30;
      }

      td:first-of-type{
        border-right: 1px solid ${p => p.theme.colors.grey}30;
        padding-right: 1rem;
      }

      td:last-child{
        padding-left: 1rem;
      }

    }
  }

  h2, p.title-legals {
    color: ${p => p.theme.colors.black};
    font-weight: 900;
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  h3 {
    font-family: ${({ theme }) => theme.fonts.serif};
    color: ${p => p.theme.colors.black};
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 10px;
    line-height: 3rem;
  }

  a, a:visited {
    color: ${p => p.theme.colors.grey};
  }

  .cookie-settings-button {
    text-decoration: underline;
    cursor: pointer;
  }

`
